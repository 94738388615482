import React from "react";
import styled from "styled-components";
import facebook from "../icons/facebook.svg";
import twitter from "../icons/twitter.svg";
import github from "../icons/github.svg";
import linkedin from "../icons/linkedin.png";

const SocialMedia = () => {
  return (
    <SocialMediaStyled>
      <span>Find Me on</span>
      <ul>
	  <li>
          <a
            href="https://www.linkedin.com/in/jainesh-patel-7a306a124/"
            rel="noreferrer"
            target="_blank"
          >
            <img src={linkedin} alt="Linkedin" />
          </a>
        </li>
	  <li>
          <a
            href="https://github.com/JaineshPateldev"
            rel="noreferrer"
            target="_blank"
          >
            <img src={github} alt="Github" />
          </a>
        </li>
     
      
      </ul>
    </SocialMediaStyled>
  );
};

const SocialMediaStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  span {
    display: block;
    font-size: 30px;
    color: white;
    font-weight: 200;
    margin-bottom: 17px;
  }
  ul {
    display: flex;
    align-items: center;
    li {
      margin-right: 10px;
      transition: all 0.5s;
      border-radius: 50%;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 2.375rem;
        height: 2.375rem;
        padding: 0;
        box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
        border-radius: 50%;
      }
      &:first-child {
        a {
          color: #fff;
		  background-color: #3b5999;
          border-color: #3b5999;
        }
      }
      &:nth-of-type(2) {
        a {
         
		    background-color: #222;
          border-color: #222;
        }
      }
      &:nth-of-type(3) {
        a {
          background-color: #222;
          border-color: #222;
        }
      }
      &:hover {
        box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
        transform: translateY(-1px);
      }
    }
  }
`;

export default SocialMedia;
